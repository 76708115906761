
import { IonPage, IonCard, IonItem, IonLabel, IonButton, IonInput, IonToast
  , IonCardHeader , IonCardTitle, IonLoading} from '@ionic/vue'
import { logIn, personAdd } from 'ionicons/icons';
import AuthService from '../services/auth.service';

function initialState(){
  return {
    resetAccount: {
      newPassword: '',
      passwordConfirm:'',
      key:''
    },
    loading: false,
    message:'',
    success: null,
    error: null,
    keyMissing: false,
    selectedColor: 'primary'
  }
}

export default {
  name: 'Reset',
  components: { IonPage,
    IonCard,
    IonItem,
    IonLabel,
    IonButton,
    IonInput,
    IonCardTitle,
    IonCardHeader,
    IonLoading,
    IonToast
  },
  setup() {

    return {
      logIn,
      personAdd
    };
  },
  data() {
    return initialState();
  },
  computed: {

  },
  created(): void {
    if (this.$route !== undefined && this.$route.query !== undefined && this.$route.query.key !== undefined) {
      console.log(this.$route.query.key);
      this.resetAccount.key = this.$route.query.key;
    }
    this.keyMissing = !this.key;
  },
  methods: {
    finishReset(){
      this.doNotMatch = null;
      this.success = null;
      this.error = null;
      if(this.resetAccount.newPassword !== this.resetAccount.passwordConfirm){
        this.message="Passwords do not match";
        this.loading = false;
        this.error =true;
        this.selectedColor="danger";
      }else{
        AuthService.reset(this.resetAccount)
            .then(() => {
              this.success= true;
              this.message = "Change Password Successfully!!";
              this.resetAll();
            })
            .catch(() => {
              this.success = null;
              this.error = true;
              this.message = "Something went wrong, try again later"
              this.loading = false;
              this.selectedColor="danger";
            });

      }
    },

    resetAlert(){
      this.loading= false,
      this.message ='',
      this.success= null,
      this.error= null,
      this.selectedColor= 'primary'
    },

    resetAll(){
      if(this.success){
        this.$router.replace("/login");
      }
      Object.assign(this.$data, initialState());
    }

  }
}

